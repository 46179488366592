.leaflet-container {
    width: 100%;
    height: 100%;
    background: transparent;
}

.map {
    height: 100%;
    width: 100%;
}
.leaflet {
    height: 100%;
}

.heatmap-overlay {
    filter: brightness(0.5);
}

.dark-popup {
    background-color: lightblue;
    color: lightblue;
}

.animated-marker {
    transition: transform 0.3s ease-in-out
}
